import React, { Fragment } from 'react';
import { variable } from '../helpers';
import doc from 'jspdf';

type PageProps = {
  contents: any; // Temporary shape for testing purpose
};

function Page({ contents }: PageProps) {
  let {
    clientname,
    sex,
    clientaddress,
    emailaddress,
    dob,
    homephone,
    cellphone,
    workphone,
    permission,
    insurancecompanyname,
    policyidnumber,
    policiyholdername,
    policyholderdob,
    relationshiptopatient,
    policyholderaddress,
    providerinquirynumber,
    secondarypolicyholdername,
    secondarypolicyholderdob,
    relationshiptoclient,
    secondaryinsurancecompanyname,
    secondaryproviderinquirynumber,
    secondarypolicyholderaddress,
    secondarymentalhealthcarrier,
    secondaryinsuranceid,
  } = contents;

  return (
    <Fragment>
      <hr />
      <div>
        <h2>Review Your Information</h2>
      </div>
      <div className="page">
        <h2>{variable.DOC_NAME}</h2>
        <p>{variable.SITE_TAGLINE}</p>
        <h3>INTAKE INFORMATION</h3>
        <PageItem name={'Client Name'} value={clientname} />
        <PageItem name={'Sex'} value={sex} />
        <PageItem name={'Client Address'} value={clientaddress} />
        <PageItem name={'Email Address'} value={emailaddress} />
        <PageItem name={'Date of Birth'} value={dob} />
        <p>
          <InlineItem name={'Cell'} value={cellphone} />
          <InlineItem name={'Work'} value={workphone} />
          <InlineItem name={'Home'} value={homephone} />
        </p>
        <p>
          {variable.PERMISSION_TEXT}{' '}
          <strong>{permission ? 'Yes' : 'No'}</strong>
        </p>

        <h3>INSURANCE INFORMATION</h3>
        <PageItem
          name={'Insurance Company Name'}
          value={insurancecompanyname}
        />
        <PageItem name={'Policy ID number'} value={policyidnumber} />
        <PageItem name={'Policy Holder name'} value={policiyholdername} />
        <PageItem
          name={'Policy Holder Date of Birth'}
          value={policyholderdob}
        />
        <PageItem
          name={'Relationship to Patient'}
          value={relationshiptopatient}
        />
        <PageItem name={'Policy Holder Address'} value={policyholderaddress} />
        <PageItem
          name={'Provider inquiry number'}
          value={providerinquirynumber}
        />
        <h3>SECONDARY INSURANCE</h3>
        <PageItem
          name={'Secondary Policy holder name'}
          value={secondarypolicyholdername}
        />
        <PageItem
          name={'Secondary Policy holder DOB'}
          value={secondarypolicyholderdob}
        />
        <PageItem
          name={'Relationship to client'}
          value={relationshiptoclient}
        />
        <PageItem
          name={'Secondary Insurance company name'}
          value={secondaryinsurancecompanyname}
        />
        <PageItem
          name={'Secondary Provider inquiry Number'}
          value={secondaryproviderinquirynumber}
        />
        <PageItem
          name={'Secondary Policy holder address'}
          value={secondarypolicyholderaddress}
        />
        <PageItem
          name={'Mental Health Carrier'}
          value={secondarymentalhealthcarrier}
        />
        <PageItem
          name={'Secondary Insurance ID'}
          value={secondaryinsuranceid}
        />
      </div>
    </Fragment>
  );
}

function PageItem({ name, value }: { name: string; value: string }) {
  return (
    <p>
      {name}: <strong>{value}</strong>
    </p>
  );
}

function InlineItem({ name, value }: { name: string; value: string }) {
  if (value) {
    return (
      <span>
        {name} Phone: <strong>{value}</strong>{' '}
      </span>
    );
  } else {
    return null;
  }
}

export default Page;
