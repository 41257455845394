import jsPDF from 'jspdf';

/**
 * Creates a PDF document from the given element
 * @param elementClass class name of the element to target
 */
export default function getPDF(elementClass: string): jsPDF {
  var doc = new jsPDF();

  var element =
    document.querySelector(elementClass)?.innerHTML || '<h1>Hello</h1>';

  doc.fromHTML(element, 10, 10);

  return doc;
}
