import { debug } from './index';
import jsPDF from 'jspdf';

export default function sendMail(email: string, doc: jsPDF) {
  let docString = btoa(doc.output());
  const msg = {
    to: email,
    subject: 'client information',
    doc: docString,
  };

  fetch('https://pdf-email-api.herokuapp.com/sendmail', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(msg),
  })
    .then((res) => res.json())
    .then((res) =>
      alert(`${res.info}. You will get an email with information shortly`)
    )
    .catch((err) => {
      debug(err);
      alert('Failed to send email, Please try again later');
    });
}
