/**
 * The helper is a utility file that holds
 * many reusable functions
 * All the functions are in their own seperate files
 * This one only has a debug function
 */
import { DEBUG_MODE } from '../config';

function debug(...args: any) {
  if (DEBUG_MODE) {
    console.log('DEBUG: ', ...args);
  }
}

const variable = {
  DOC_NAME: 'Steven B. Smith, Ph.D.',
  SITE_NAME: 'Wisdom of the Heart',
  SITE_TAGLINE: 'Licensed Psychologist',
  PERMISSION_TEXT: 'May I contact you at work if needed?',
  TERMS_TEXT:
    'from Dr. Smith, understand these policies, and have agreed to comply with them.',
};

export { debug, variable };
