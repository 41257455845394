import { createStore } from 'redux';

import { debug } from '../helpers';

var defaultState = {
  formContents: null,
};

type Action = {
  type: string;
  value: any;
};

function reducer(state: any, action: Action) {
  let { type, value } = action;

  let formContents = {};

  if (type === 'SUBMIT_FORM') {
    formContents = { ...value };
    debug('New state value', formContents);
    return { formContents };
  }

  if (type === 'BACK_BUTTON') {
    return { formContents: null };
  }

  return state;
}

var store = createStore(reducer, defaultState);

export default store;
