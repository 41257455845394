import React, { useState } from 'react';

type TextInputProp = {
  name: string;
  required?: boolean;
};

function TextInput({ name, required }: TextInputProp) {
  const [inputValue, updateValue] = useState('');

  let title = name;
  let nameField = name
    .split(' ')
    .map((word) => word.toLocaleLowerCase())
    .join('');

  return (
    <p className="field">
      <label htmlFor={nameField}>
        {title}: {required ? '(Required)' : null}{' '}
      </label>
      <input
        onChange={(e) => updateValue(e.target.value)}
        type="text"
        name={nameField}
        value={inputValue}
        required={required}
      />
    </p>
  );
}

function DateInput({ name }: TextInputProp) {
  let title = name;
  let nameField = name
    .split(' ')
    .map((word) => word.toLocaleLowerCase())
    .join('');

  return (
    <p className="field">
      <label htmlFor={nameField}>{title}:</label>
      <input type="date" name={nameField} />
    </p>
  );
}

export { TextInput, DateInput };
