import React, { Fragment } from 'react';
import { TextInput } from './TextInput';

import { debug, variable } from '../helpers';
import { connect } from 'react-redux';

function IntakeForm(props: any) {
  function formAction(event: any, props: any) {
    event.preventDefault();

    let formValue: any = {};

    document.querySelectorAll('.field').forEach((item: any) => {
      formValue[item.firstElementChild?.htmlFor] = item.lastElementChild?.value;
    });

    // Get the permission value

    let checkElement = document.querySelector(
      '.permission'
    ) as HTMLInputElement;

    formValue.permission = checkElement.checked;

    debug(formValue);
    props.dispatch({ type: 'SUBMIT_FORM', value: formValue });
  }

  return (
    <Fragment>
      <div>
        <h2>Enter your information</h2>
      </div>

      <div className="intakeForm">
        <h2>Intake Information</h2>
        <form onSubmit={(event) => formAction(event, props)}>
          <TextInput name="Client Name" required={true} />
          <TextInput name="Sex" required={true} />
          <TextInput name="Client Address" required={true} />
          <TextInput name="Email Address" required={true} />
          <TextInput name="DOB" required={true} />
          <TextInput name="Cell Phone" />
          <TextInput name="Work Phone" />
          <TextInput name="Home Phone" />
          <p>
            {variable.PERMISSION_TEXT}{' '}
            <input
              type="checkbox"
              name="permission"
              className="checkbox permission"
            />{' '}
            Yes
          </p>

          <h3>Insurance Information</h3>
          <TextInput name="Insurance company name" />
          <TextInput name="Policy ID number" />
          <TextInput name="Policiy holder name" />
          <TextInput name="Policy holder DOB" />
          <TextInput name="Relationship to patient" />
          <TextInput name="Policy holder address" />
          <TextInput name="Provider inquiry number" />
          <span>(on back of card)</span>
          <h3>Secondary Insurance</h3>
          <TextInput name="Secondary Policy holder name" />
          <TextInput name="Secondary Policy holder DOB" />
          <TextInput name="Relationship to client" />
          <TextInput name="Secondary Insurance company name" />
          <TextInput name="Secondary Provider inquiry number" />
          <span>(on back of card)</span>
          <TextInput name="Secondary Policy holder address" />
          <TextInput name="Secondary Mental health carrier" />
          <TextInput name="Secondary Insurance ID" />
          <p>
            <strong>Terms and Conditions</strong>
          </p>
          <p>
            <input
              className="checkbox"
              type="checkbox"
              name=""
              id=""
              required
            />
            I have read the{' '}
            <a
              href="https://www.wisdomoftheheartinc.com/wp-content/uploads/2019/09/Client-Forms-2019-October.pdf"
              target="_blank">
              following documents
            </a>{' '}
            {variable.TERMS_TEXT}
          </p>
          <button>Update and Preview</button>
          <p>After clicking Update, Scroll down and click "Send"</p>
        </form>
      </div>
    </Fragment>
  );
}

export default connect()(IntakeForm);
