import { debug, variable } from './helpers';
import sendMail from './helpers/sendMail';
import getPDF from './helpers/getPDF';
import React from 'react';
import { connect } from 'react-redux';
import './App.css';
import Page from './componentes/Page';
import IntakeForm from './componentes/IntakeForm';

function App(props: any) {
  return (
    <div className="App">
      <h1>{variable.DOC_NAME}</h1>
      <p>{variable.SITE_TAGLINE}</p>
      <IntakeForm />
      {props.formContents ? <Page contents={props.formContents} /> : null}
      {props.formContents ? (
        <div className="send">
          <button
            onClick={() =>
              sendInformationAction(props.formContents.emailaddress)
            }>
            Send The Information
          </button>{' '}
          <button onClick={downloadPDFAction}>Download a PDF Copy</button>
          <br />
        </div>
      ) : null}
    </div>
  );

  function sendInformationAction(email: string) {
    let doc = getPDF('.page');
    // Wait for the sending and change state or something
    sendMail(email, doc);
  }

  function downloadPDFAction() {
    let doc = getPDF('.page');
    doc.save(`intake-information-pdf-${Date.now()}.pdf`);
  }
}

function mapStateToProps(state: any) {
  return { formContents: state.formContents };
}

export default connect(mapStateToProps)(App);
